import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

import Main from "./components/main.tsx";
import Login from "./components/login.tsx";
import AllUsers from "./components/allUsers.tsx";
import Groups from "./components/groups.tsx";
import GroupList from "./components/groupList.tsx";
import HeadquarterList from "./components/headquarterList.tsx";
import Stream from "./components/stream.tsx";
import Register from "./components/register.tsx";
import ResetPassword from "./components/resetPassword.tsx";
import Verification from "./components/verification.tsx";

import { DefaultRoute } from "./components/DefaultRoute.jsx";
import { ProtectedRoute } from "./components/ProtectedRoute.jsx";
import { AdminRoute } from "./components/AdminRoute.jsx";
import { HeadquarterRoute } from "./components/HeadquarterRoute.jsx";
import { GroupRoute } from "./components/GroupRoute.jsx";
import AdminMenu from "./components/AdminMenu.tsx";
import HeadquarterMenu from "./components/HeadquarterMenu.tsx";
import GroupMenu from "./components/GroupMenu.tsx";

import { AuthProvider } from "./firebase/auth.tsx";
import DefaultPageView from "./components/defaultPageView.tsx";
import Footer from "./components/footer.tsx";

// FIXME: CHANGE FIREBASE WRITING RULES

const App: React.FC = () => {
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [headquarters, setHeadquarters] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get("mode");
    const oobCode = searchParams.get("oobCode");

    if (oobCode) {
      const newPath =
        mode === "resetPassword"
          ? `/resetPassword?mode=${mode}&oobCode=${oobCode}`
          : mode === "verifyEmail"
          ? `/verification?mode=${mode}&oobCode=${oobCode}`
          : null;

      // Only navigate if the new path is different from the current path
      if (newPath && location.pathname !== newPath) {
        navigate(newPath);
      }
    }
  }, [location.pathname, location.search, navigate]);

  const updateHeadquarters = (newHeadquarter) => {
    setHeadquarters([...headquarters, newHeadquarter]);
  };

  const updateGroups = (newGroup) => {
    setGroups([...groups, newGroup]);
  };

  const updateUserList = (newUser) => {
    setUserList([...userList, newUser]);
  };

  return (
    <AuthProvider>
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <DefaultPageView />
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/register" element={<Register />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/verification" element={<Verification />} />
            <Route
              path="/login"
              element={
                <DefaultRoute>
                  <Login />
                </DefaultRoute>
              }
            />
            <Route
              path="/main"
              element={
                <ProtectedRoute fullScreenMode={fullScreenMode}>
                  <Main />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stream/:streamingUserId"
              element={
                <ProtectedRoute fullScreenMode={fullScreenMode}>
                  <Stream
                    fullScreenMode={fullScreenMode}
                    setFullScreenMode={setFullScreenMode}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/allUsers"
              element={
                <ProtectedRoute fullScreenMode={fullScreenMode}>
                  <AdminRoute>
                    <AdminMenu
                      updateHeadquarters={updateHeadquarters}
                      updateGroups={updateGroups}
                      updateUserList={updateUserList}
                      selector={"Users"}
                    />
                    <AllUsers userList={userList} setUserList={setUserList} />
                  </AdminRoute>
                </ProtectedRoute>
              }
            />
            <Route
              path="/groups"
              element={
                <ProtectedRoute fullScreenMode={fullScreenMode}>
                  <AdminRoute>
                    <AdminMenu
                      updateHeadquarters={updateHeadquarters}
                      updateGroups={updateGroups}
                      updateUserList={updateUserList}
                      selector={"Hospitals"}
                    />
                    <Groups groups={groups} setGroups={setGroups} />
                  </AdminRoute>
                </ProtectedRoute>
              }
            />
            <Route
              path="/headquarterList"
              element={
                <ProtectedRoute fullScreenMode={fullScreenMode}>
                  <AdminRoute>
                    <AdminMenu
                      updateHeadquarters={updateHeadquarters}
                      updateGroups={updateGroups}
                      updateUserList={updateUserList}
                      selector={"Headquarters"}
                    />
                    <HeadquarterList
                      headquarters={headquarters}
                      setHeadquarters={setHeadquarters}
                    />
                  </AdminRoute>
                </ProtectedRoute>
              }
            />
            <Route
              path="/groupList/headquarter/:headquarterId"
              element={
                <ProtectedRoute fullScreenMode={fullScreenMode}>
                  <HeadquarterRoute>
                    <HeadquarterMenu
                      updateGroups={updateGroups}
                      updateUserList={updateUserList}
                    />
                  </HeadquarterRoute>
                  <GroupList groups={groups} setGroups={setGroups} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/userList/headquarter/:headquarterId"
              element={
                <ProtectedRoute fullScreenMode={fullScreenMode}>
                  <HeadquarterRoute>
                    <HeadquarterMenu
                      updateGroups={updateGroups}
                      updateUserList={updateUserList}
                    />
                  </HeadquarterRoute>
                  <AllUsers userList={userList} setUserList={setUserList} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/userList/group/:groupId"
              element={
                <ProtectedRoute fullScreenMode={fullScreenMode}>
                  <GroupRoute>
                    <GroupMenu updateUserList={updateUserList} />
                    <AllUsers userList={userList} setUserList={setUserList} />
                  </GroupRoute>
                </ProtectedRoute>
              }
            />
            <Route
              path="/userList/:headquarterId/:groupId"
              element={
                <ProtectedRoute fullScreenMode={fullScreenMode}>
                  <GroupRoute>
                    <GroupMenu updateUserList={updateUserList} />
                    <AllUsers userList={userList} setUserList={setUserList} />
                  </GroupRoute>
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default App;
